<template>
  <div class="card">
    <div class="card-header">
      <a-button class="mr-2" @click="showModalNewFile">
        <template #icon><i class="fa fa-plus" /></template>
      </a-button>
      <a-button
        class="mr-2"
        :disabled="selectedRowKeys.length != 1"
        @click="editEntity(selectedRows)"
      >
        <template #icon><i class="fa fa-pencil" /></template>
      </a-button>
      <a-button
        class="mr-2"
        :disabled="selectedRowKeys.length == 0"
        @click="deleteEntity(selectedRowKeys)"
      >
        <template #icon><i class="fa fa-trash" /></template>
      </a-button>
    </div>

    <a-table
      :dataSource="gridData"
      :columns="gridColumns"
      :scroll="{ x: 'calc(100%)', y: '100%' }"
      :customRow="rowClick"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      rowKey="id"
    />
    
    <a-modal
      width="100"
      v-model:visible="visibleFile"
      title="Файлы"
      @ok="handleOkFile"
    >
      <a-form ref="FileForm" :model="FileFormState">
        <a-form-item label="Uniform Resource Locator (URL)">
          <a-input v-model:value="FileFormState.FileURL" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";

export default {
  name: "File",
  data() {
    return {
      gridColumns: [
        { dataIndex: "id", title: "ID" },
        { dataIndex: "url", title: "URL" },
      ],
      gridData: [],
      visibleFile: false,
      thisFile: [],
      FileFormState: {
        FileID: undefined,
        FileURL: undefined,
      },
      selectedRowKeys: [],
      selectedRows: [],
    };
  },
  methods: {
    async reload() {
      this.gridData = (await axios.get("file")).data;
    },
    rowClick(record, index) {
      return {
        onClick: () => {
          console.log(record, index, 1111);
          this.thisFile = record;
          this.FileFormState.FileURL = record.url;
          this.FileFormState.FileID = record.id;
          console.log(this.thisFile);
          this.showModalFile();
        },
      };
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    showModalFile() {
      this.visibleFile = true;
    },
    showModalNewFile() {
      this.FileFormState.FileID = undefined;
      this.FileFormState.FileURL = undefined;
      this.visibleFile = true;
    },
    async handleOkFile() {
      console.log("FileModal.");
      this.visibleFile = false;
      if (this.FileFormState.FileURL !== undefined) {
        if (this.FileFormState.FileID == undefined) {
          try {
            await axios.post("file", { url: this.FileFormState.FileURL });
          } finally {
            this.FileFormState.FileID = undefined;
            this.FileFormState.FileURL = undefined;
            await this.reload();
          }
        } else {
          console.log("FileModal.");
          console.log({
            id: this.FileFormState.FileID,
            title: this.FileFormState.FileURL,
          });
          try {
            await axios.put("file", {
              id: this.FileFormState.FileID,
              title: this.FileFormState.FileURL,
            });
          } finally {
            this.FileFormState.FileID = undefined;
            this.FileFormState.FileURL = undefined;
            await this.reload();
          }
        }
      } else {
        return;
      }
    },
    async deleteEntity(selectedRowKeys) {
      console.log(selectedRowKeys);
      try {
        await axios.delete("file", {
          headers: { "Content-Type": "application/json; charset=utf-8" },
          data: selectedRowKeys,
        });
      } finally {
        selectedRowKeys = undefined;
        await this.reload();
      }
    },
    async editEntity(selectedRows) {
      console.log(selectedRows);
      this.FileFormState.FileID = selectedRows[0].id;
      this.FileFormState.FileURL = selectedRows[0].url;
      this.showModalFile();
    },
  },
  async created() {
    await this.reload();
  },
};
</script>